import 'froala-editor/js/plugins/align.min'
import 'froala-editor/js/plugins/code_view.min'
import 'froala-editor/js/plugins/colors.min'
import 'froala-editor/js/plugins/draggable.min'
import 'froala-editor/js/plugins/entities.min'
import 'froala-editor/js/plugins/image.min'
import 'froala-editor/js/plugins/link.min'
import 'froala-editor/js/plugins/lists.min'
import 'froala-editor/js/plugins/paragraph_format.min'
import 'froala-editor/js/plugins/video.min'
import { Controller } from "@hotwired/stimulus"
import FroalaEditor from "froala-editor"

export default class extends Controller {
  static values = {
    id: String
  }

  connect(_event){
    new FroalaEditor(this.element, {
      pluginsEnabled: [
        'align',
        // 'char_counter',
        // 'code_beautifier',
        'code_view',
        'colors',
        'draggable',
        // 'embedly',
        // 'emoticons',
        'entities',
        // 'file',
        // 'font_awesome',
        // 'font_size',
        // 'full_size',
        // 'help',
        'image',
        // 'image_manager',
        // 'image_tui',
        // 'inline_class',
        // 'inline_style',
        // 'line_breaker',
        // 'line_height',
        'link',
        'lists',
        'paragraph_format',
        // 'paragraph_style',
        // 'print',
        // 'quick_insert',
        // 'quote',
        // 'save',
        // 'spell_checker',
        // 'table',
        // 'url',
        'video'
        // 'word_paste'
      ],
      imageUploadURL: `/admin/articles/${this.idValue}/upload_image`,
      imageUploadParams: { id: this.idValue }
    });
  }
}
